
import { Component, Vue, Prop } from "vue-property-decorator";
import { dispAuthFetch } from "@/store/mlm/actions";

@Component
export default class ConnectMFAModal extends Vue {
  @Prop({ required: true }) readonly modalShow!: boolean;

  public currentStep: "initial" | "success" = "initial";
  public qrCodeUrl: string = "";
  public verificationCode: string = "";
  public verificationError: boolean = false;
  public loading: boolean = false;

  async mounted() {
    await this.generateQRCode();
  }

  public async generateQRCode() {
    try {
      const response = await dispAuthFetch(this.$store, {
        input: "/api/v1/accounts/generate_qr_code/",
        init: {
          method: "GET",
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          this.qrCodeUrl = reader.result as string;
        };
        reader.readAsDataURL(blob);
      }
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  }

  public async handleVerify() {
    if (!this.verificationCode) return;

    this.verificationError = false;
    this.loading = true;

    try {
      const response = await dispAuthFetch(this.$store, {
        input: "/api/v1/accounts/verify_qr_code/",
        init: {
          method: "POST",
          body: {
            code: this.verificationCode,
          },
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.valid) {
          this.currentStep = "success";
        } else {
          this.verificationError = true;
        }
      } else {
        this.verificationError = true;
      }
    } catch (error) {
      this.verificationError = true;
    } finally {
      this.loading = false;
    }
  }

  public handleClose() {
    this.$emit("close-modal");
  }
}
